import classNames from 'classnames'
import React from 'react'

const Checkbox = ({label, className, name, checked, onChange, isDisabled}) => {
  return (
    <label className={`checkbox-item ${className}`}>
      <input
        className={classNames({grayscale: isDisabled}, 'cursor-pointer')}
        name={name}
        type="checkbox"
        onChange={onChange}
        checked={checked}
        disabled={isDisabled}
      />
      {label && <span>{label}</span>}
    </label>
  )
}

export default Checkbox
