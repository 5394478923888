import {COLOMBIA_CODE, PERU_CODE} from 'helpers/country'

import {hasFoundContractType} from '../../../helpers/socialsecurity'
import {isEqual} from '../../../helpers/utils'

export const validateSocialSecurityData = (formData, contractTypeEntityType, countryCode) => {
  let response = {hasErrors: false, errors: ''}

  // validate workspace
  if (
    hasFoundContractType('ARL', formData.contract_type_id, contractTypeEntityType) &&
    isEqual(formData.workplace_id, 0)
  ) {
    response.hasErrors = true
    response.errors += '\nEl campo Centro de trabajo ARL es requerido para el tipo de contrato.'
  }

  // validate pensioner type for colombia
  if (
    isEqual(countryCode, COLOMBIA_CODE) &&
    formData.is_pensioner &&
    isEqual(formData.mapping.pila.subcontributor, 0)
  ) {
    response.hasErrors = true
    response.errors += '\nEl campo Tipo Pensionado es requerido porque marcaste el empleado como pensionado.'
  }

  // Validate additional fields Peru
  if (isEqual(countryCode, PERU_CODE)) {
    if (formData.afp_commission_type === '') {
      response.hasErrors = true
      response.errors += '\nEl campo Tipo de Comisión AFP es requerido para el tipo de contrato.'
    }
  }

  // validate entities
  formData.entities.forEach(e => {
    if (!e.is_show) return
    if (!e.is_required) return
    if (e.social_security_entity_id !== 0) return

    response.hasErrors = true
    response.errors += `\nEl campo ${e.description} es requerido para el tipo de contrato.`
  })

  return response
}
