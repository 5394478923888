import {isEqual, isUndefined} from '../../../helpers/utils'
import {useEmployeeDataContext} from '../../../modules/employee-view/providers/EmployeeData'
import EmployeesHeader from '../Headers/EmployeesHeader'
import ContractPanel from '../Panels/ContractPanel'
import NotesContractPanel from '../Panels/NotesContractPanel'
import PaymentDataPanel from '../Panels/PaymentDataPanel'
import PersonaInformationData from '../Panels/PersonaInformationData'
import RetentionPanel from '../Panels/RetentionPanel'
import SocialSecurityPanel from '../Panels/SocialSecurityPanel'
import EmployeeInformationGridSkeleton from '../Skeleton/EmployeeInformationGridSkeleton'
import React from 'react'

const EmployeeInformationGrid = () => {
  const {employee, setEmployee, isLoading, contractSelected, setContractSelected} = useEmployeeDataContext()

  return isLoading ? (
    <EmployeeInformationGridSkeleton />
  ) : // @ts-ignore
  isUndefined(employee.employee) ? null : (
    <>
      <div className="lg-cols-2">
        <EmployeesHeader
          employee={employee}
          contractSelected={contractSelected}
          onChangeContractSelected={setContractSelected}
          onUpdate={setEmployee}
        />
      </div>

      <div>
        <div className="ed-grid row-gap">
          {/* @ts-ignore */}
          <PersonaInformationData data={employee.employee} onUpdate={setEmployee} />
          <SocialSecurityPanel contractData={contractSelected} setContractData={setContractSelected} />
          <NotesContractPanel data={contractSelected} onUpdate={setContractSelected} />
        </div>
      </div>

      <div>
        <div className="ed-grid row-gap">
          <ContractPanel data={contractSelected} onUpdate={setContractSelected} />
          <PaymentDataPanel data={contractSelected} onUpdate={setContractSelected} />
          <RetentionPanel data={contractSelected} onUpdate={setContractSelected} />
        </div>
      </div>
    </>
  )
}

export const validIdentificationParam = (identification, identiTypes) => {
  if (!Array.isArray(identiTypes)) return {isValid: false}
  if (identification.length < 3) return {isValid: false}

  const documentType = identification.slice(0, 2).toUpperCase()
  const documentNumber = identification.slice(2, identification.length)
  const found = identiTypes.filter(ident => isEqual(ident.abbreviation, documentType))

  if (!isEqual(found.length, 1)) return {isValid: false}
  if (!found[0].is_alphanumeric && isNaN(Number(documentNumber))) return {isValid: false}

  return {isValid: true, type: documentType, number: documentNumber}
}

export default EmployeeInformationGrid
