import React, {forwardRef, useState} from 'react'

import {isEqual} from '../../../helpers/utils'
import RadioButton from '../../Atoms/Forms/RadioButton'
import ModalTemplate from '../../Wrappers/ModalTemplate'
import RadioButtonsWrapper from '../../Wrappers/RadioButtonsWrapper'

const TYPE_NORMAL = 'NORMAL'
const TYPE_REPLACEMENT = 'REPLACEMENT'

const ElectronicPayrollPrepareModal = forwardRef(({onAccept = () => {}}, ref) => {
  const [documentType, setDocumentType] = useState(TYPE_NORMAL)

  const handleAccept = () => {
    onAccept(isEqual(documentType, TYPE_REPLACEMENT))
    ref.current.closeModal()
  }

  const resetForm = isOpen => {
    if (!isOpen) return

    setDocumentType(TYPE_NORMAL)
  }

  const updateRadioButton = e => {
    const value = e.target.value

    setDocumentType(value)
  }

  return (
    <ModalTemplate ref={ref} className="s-center medium" onChangeOpen={resetForm}>
      <h3>Prepara tus documentos</h3>
      <div className="ed-grid s-grid-1 rows-gap s-mb-2">
        <p className="s-mb-0">
          Esta acción eliminará los borradores y creará nuevos documentos, selecciona el tipo de documentos a preparar:
        </p>
        <div>
          <RadioButtonsWrapper label="Tipo Documento" className="s-mb-1">
            <RadioButton
              name="selection_type"
              label="Documentos normales"
              value={TYPE_NORMAL}
              onChange={updateRadioButton}
              checked={isEqual(documentType, TYPE_NORMAL)}
            />

            <RadioButton
              name="selection_type"
              label="Documentos de remplazo"
              value={TYPE_REPLACEMENT}
              onChange={updateRadioButton}
              checked={isEqual(documentType, TYPE_REPLACEMENT)}
            />
          </RadioButtonsWrapper>
        </div>
      </div>

      <button className="button" onClick={handleAccept}>
        Aceptar
      </button>
    </ModalTemplate>
  )
})

ElectronicPayrollPrepareModal.displayName = 'ElectronicPayrollPrepareModal'

export default ElectronicPayrollPrepareModal
