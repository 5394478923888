import React, {useContext, useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {useLocation, useParams} from 'react-router-dom'

import PayslipSkeleton from '../components/Organisms/Skeleton/PayslipSkeleton'
import {PayrollContext} from '../contexts/contexts'
import {NOTIFICATION_PROCESS_FATAL, NOTIFICATION_PROCESS_RUN, READ} from '../helpers/notification-user'
import {findProcessStage, STAGE_PAYSHEET, STATUS_HISTORY} from '../helpers/payroll'
import {isAnyEqual, isEmpty, isEqual, isNull, isUndefined} from '../helpers/utils'
import {selectors as notificationUserSelectors} from '../redux/ducks/notificationUsers'
import {getActiveProcessLock} from '../services/processLock'
import Payslip from './Payslip'
import PayslipCalculating from './PayslipCalculating'
import PayslipCalculationCompleted from './PayslipCalculationCompleted'

const PaySheet = () => {
  const {hash: currentProcessHash} = useParams()
  const {hash} = useLocation()
  const {activeProcess} = useContext(PayrollContext)
  const [isCalculationCompleted, setIsCalculationCompleted] = useState(false)
  const [processLock, setProcessLock] = useState({user_picture: '', user_name: ''})
  const notifications = useSelector(notificationUserSelectors.getAll)
  const newNotificationTimestamp = useSelector(notificationUserSelectors.getHasNewNotificationTimestamp)

  // we checks always that the user is move to location #nomina
  useEffect(() => {
    if (isEqual(activeProcess.status, STATUS_HISTORY)) {
      setProcessLock(null)
      return
    }

    if (!isEqual(hash, findProcessStage(STAGE_PAYSHEET)?.hash)) {
      return
    }

    // if the process has status PRE_PAYROLL checks if there is process calculation for the
    // company
    getActiveProcessLock(setProcessLock, currentProcessHash)
  }, [hash, activeProcess.status])

  useEffect(() => {
    if (isUndefined(newNotificationTimestamp) || isEqual(newNotificationTimestamp, 0)) return
    if (isUndefined(notifications) || isEmpty(notifications) || !Array.isArray(notifications)) return

    const newNotification = notifications[0]
    if (!isAnyEqual(newNotification?.type, [NOTIFICATION_PROCESS_RUN, NOTIFICATION_PROCESS_FATAL])) return
    if (isEqual(newNotification?.status, READ) || !isEqual(newNotification?.content?.hash, currentProcessHash)) return

    setIsCalculationCompleted(true)
    setProcessLock(null)
  }, [newNotificationTimestamp, notifications])

  if (isCalculationCompleted) {
    return <PayslipCalculationCompleted setEnabled={setIsCalculationCompleted} />
  }

  if (!isNull(processLock) && processLock.user_name === '') {
    return <PayslipSkeleton />
  }

  if (!isNull(processLock)) {
    return <PayslipCalculating processLock={processLock} />
  }

  // only show the payslip page if the process has status HISTORY, or if the process has status PRE_PAYROLL but
  // there isn't process calculation for company.
  return <Payslip />
}

export default PaySheet
