import {isEqual, isNull} from 'helpers/utils'
import React, {useState} from 'react'
import {useDispatch} from 'react-redux'
import {updateEmployeeSocialSecurityData} from 'services/employees'
import {notifyError} from 'services/notification'

import {validateSocialSecurityData} from '../../helpers/validate'
import useGetContractDataFromCache from './useGetContractDataFromCache'

const useSocialSecurityForm = ({formattedData, setContractData, handleCancel, contractId}) => {
  const [socialSecurityData, setSocialSecurityData] = useState(formattedData)
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()
  const {employerSession, cacheGeneral} = useGetContractDataFromCache()

  const contractTypeEntityType = !isNull(cacheGeneral) && cacheGeneral.data?.contract_types_entity_types

  const updateFormField = e => {
    const target = e.target
    let value = isEqual(target.type, 'checkbox') ? target.checked : target.value
    const name = target.name

    if (name.endsWith('_id')) value = Number(value)
    if (isEqual(target.type, 'number')) value = Number(value)

    if (isEqual(target.type, 'radio') && isEqual(value, 'true')) value = true
    if (isEqual(target.type, 'radio') && isEqual(value, 'false')) value = false

    setSocialSecurityData(state => ({...state, [name]: value}))
  }

  const handleSubmit = e => {
    e.preventDefault()

    const {hasErrors, errors} = validateSocialSecurityData(
      socialSecurityData,
      contractTypeEntityType,
      employerSession.code_iso_3
    )

    if (hasErrors) {
      dispatch(notifyError(errors))
      return
    }

    const payload = {
      workplace_id: socialSecurityData.workplace_id,
      is_foreigner_without_pension: socialSecurityData.is_foreigner_without_pension,
      is_pensioner: socialSecurityData.is_pensioner,
      is_colombian_living_abroad: socialSecurityData.is_colombian_living_abroad,
      has_colombian_living_abroad_pay_health: Boolean(socialSecurityData.has_colombian_living_abroad_pay_health),
      pila: {
        subcontributor: socialSecurityData.mapping.pila.subcontributor
      }
    }

    setIsLoading(true)

    updateEmployeeSocialSecurityData(
      contractId,
      payload,
      response => {
        setContractData(state => ({
          ...state,
          contract: {
            ...state.contract,
            id: response.contract_id,
            employer_id: response.employer_id,
            is_colombian_living_abroad: response.is_colombian_living_abroad,
            is_foreigner_without_pension: response.is_foreigner_without_pension,
            is_pensioner: response.is_pensioner,
            mapping: {
              ...state.contract.mapping,
              pila: {
                ...state.contract.mapping.pila,
                contributor: response.pila.contributor,
                subcontributor: response.pila.subcontributor
              }
            },
            workplace_id: response.workplace_id
          }
        }))
        setIsLoading(false)
        handleCancel()
      },
      error => {
        dispatch(notifyError(error))
        setIsLoading(false)
      }
    )
  }

  const resetForm = () => {
    setSocialSecurityData(formattedData)
  }

  return {updateFormField, handleSubmit, socialSecurityData, setSocialSecurityData, isLoading, resetForm}
}

export default useSocialSecurityForm
