import {RequestWithEmployerAuthorization} from '../helpers/request'

const RESOURSE = '/payroll-reports'

export const getByEmployerIDAndHash = (ProcessHash, queries, callback, callbackErr) => {
  const request = new RequestWithEmployerAuthorization()
  request.getFile(`${RESOURSE}/processes/${ProcessHash}?${queries}`, callback, callbackErr)
}

export const getConceptSummaryByEmployerIDAndHash = (ProcessHash, callback, callbackErr) => {
  const request = new RequestWithEmployerAuthorization()
  request.getFile(`${RESOURSE}/concepts-summarized/${ProcessHash}`, callback, callbackErr)
}

export const getPrimaReportByEmployerIDAndHash = (ProcessHash, callback, callbackErr) => {
  const request = new RequestWithEmployerAuthorization()
  request.getFile(`${RESOURSE}/processes/${ProcessHash}/bonus-payments-report`, callback, callbackErr)
}

export const getHistoryPayrollByRange = (from, to, contractsIDs, callback, callbackErr) => {
  const request = new RequestWithEmployerAuthorization()

  request.getFile(`${RESOURSE}/history?from=${from}&to=${to}&contract_ids=${contractsIDs}`, callback, callbackErr)
}

export const getEmployeeMaster = (statuses, callback, callbackErr) => {
  const request = new RequestWithEmployerAuthorization()
  request.getFile(`${RESOURSE}/master-employees?statuses=${statuses}`, callback, callbackErr)
}

export const getContingentTaxesReport = (endsAt, callback, callbackErr) => {
  const request = new RequestWithEmployerAuthorization()
  request.getFile(`${RESOURSE}/contingent-taxes?ends_at=${endsAt}`, callback, callbackErr)
}

export const getMinimumVitalReport = (processHash, callback, callbackErr) => {
  const request = new RequestWithEmployerAuthorization()
  request.getFile(`${RESOURSE}/minimum-vital/${processHash}`, callback, callbackErr)
}
