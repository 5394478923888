import classNames from 'classnames'
import React, {useRef} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import {readBackendDateAndTime} from '../../helpers/dates'
import {getHasUnreadNotifications, gethasUnreadNotifications, UNREAD} from '../../helpers/notification-user'
import {isEmpty, isEqual} from '../../helpers/utils'
import {NOTIFICATION_LIMIT_SIZE, selectors as notificationUserSelectors} from '../../redux/ducks/notificationUsers'
import {getNotificationsUser, markAllNotificationUserAsRead} from '../../services/notificationUsers'
import NotificationCount from '../../static/images/stories/notification-count.svg'
import NotificationItem from '../Molecules/Items/NotificationItem'
import Container from '../Wrappers/Container'

const NotificationsPanel = () => {
  const dispatch = useDispatch()
  const listContainer = useRef()

  const notifications = useSelector(notificationUserSelectors.getAll)
  const hasNexPage = useSelector(notificationUserSelectors.getHasNextPage)
  const nextPage = useSelector(notificationUserSelectors.getNextPage)
  const hasUnreadNotifications = getHasUnreadNotifications(notifications)

  const handleMarkAllAsRead = e => {
    if (!hasUnreadNotifications) return

    dispatch(markAllNotificationUserAsRead())
  }

  const handleScroll = e => {
    // if the list reached its maximum limit stops, for avoid more request to backend
    if (notifications.length >= 100) return

    const container = listContainer.current

    // if the scroll is in the bottom of the container then request to backend the next page
    if (container.offsetHeight + container.scrollTop >= container.scrollHeight) {
      if (!hasNexPage) return

      dispatch(getNotificationsUser(nextPage, NOTIFICATION_LIMIT_SIZE))
    }
  }

  return (
    <Container className="notifications-panel overflow-auto">
      {isEmpty(notifications) ? (
        <div className="s-column s-cross-center s-main-center s-center nowrap">
          <img className="s-w-128px s-mb-24px" src={NotificationCount} alt="notificaciones vacías" />
          <h3 className="s-mb-6px">Aún no tienes notificaciones</h3>
          <p className="s-mb-0">Pero te avisaremos cuando tengas alguna</p>
        </div>
      ) : (
        <>
          <header className="s-main-justify">
            <h3>Notificaciones</h3>
            <span
              className={classNames(
                {
                  's-color-blue cursor-pointer': hasUnreadNotifications,
                  'grayscale cursor-not-allowed': !hasUnreadNotifications
                },
                's-mb-2'
              )}
              onClick={handleMarkAllAsRead}
            >
              Marcar como leídas
            </span>
          </header>
          <div
            className="notifications-list s-column nowrap s-flex-gap-4px"
            ref={listContainer}
            onScroll={handleScroll}
          >
            {notifications.map(notification => (
              <NotificationItem
                key={notification.id}
                itemID={notification.id}
                unread={isEqual(notification.status, UNREAD)}
                type={notification.type}
                content={notification.content}
                createdAt={readBackendDateAndTime(notification.created_at)}
              />
            ))}
          </div>
        </>
      )}
    </Container>
  )
}

export default NotificationsPanel
