import {isEqual} from 'helpers/utils'

export const getEDitSocialSecurityInitialState = ({contract}) => {
  return {
    contract_type_id: contract.contract.contract_type_id,
    is_document_foreign: Boolean(contract.contract.is_document_foreign),
    is_foreigner_without_pension: contract.contract.is_foreigner_without_pension,
    is_pensioner: contract.contract.is_pensioner,
    is_colombian_living_abroad: contract.contract.is_colombian_living_abroad,
    has_colombian_living_abroad_pay_health: Boolean(contract.contract.has_colombian_living_abroad_pay_health),
    afp_commission_type: contract.contract.afp_commission_type,

    entities: contract.contract.entities || [],
    workplace_id: contract.contract.workplace_id,
    mapping: {
      pila: {
        subcontributor: contract.contract.mapping?.pila?.subcontributor
      }
    }
  }
}

export const getEntitiesByType = (entityCode, entitiesList) => {
  if (!Array.isArray(entitiesList)) return []
  const filtered = entitiesList.filter(e => isEqual(e.entity, entityCode))[0]

  return filtered?.data || []
}

export const getIsForeignIdentificationType = (id, types) => {
  if (!Array.isArray(types)) return false

  const currentIdentification = types.find(t => isEqual(t.id, id))

  if (!currentIdentification) return false

  return currentIdentification.is_foreign
}
