import React from 'react'
import {useSelector} from 'react-redux'

import {getCacheKeyGeneral, getCacheKeySocialSecurity, getLocalCache} from '../../../../helpers/cache'
import {selectors as employerSelectors} from '../../../../redux/ducks/employer'
import {selectors as workplaceSelector} from '../../../../redux/ducks/workplaces'

const useGetContractDataFromCache = () => {
  const workplaces = useSelector(workplaceSelector.getAll)
  const employerSession = useSelector(employerSelectors.getSession)

  const cacheGeneral = getLocalCache(getCacheKeyGeneral(employerSession.code_iso_3))
  const cacheSocSec = getLocalCache(getCacheKeySocialSecurity(employerSession.code_iso_3))

  return {workplaces, employerSession, cacheGeneral, cacheSocSec}
}

export default useGetContractDataFromCache
