import {isEmpty} from '../../../helpers/utils'

export const getPayrollReportRequestQueryParams = (employeeFields, conceptTypeFields) => {
  if (isEmpty(employeeFields) || isEmpty(conceptTypeFields)) return ''

  const employeeParams = `employee_fields=${employeeFields.join(',')}`
  const conceptTypeParams = `concept_types=${conceptTypeFields.join(',')}`

  return `${employeeParams}&${conceptTypeParams}`
}
