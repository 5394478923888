import {useContext} from 'react'
import React from 'react'

import {FormDataContext} from '../../../contexts/contexts'
import SocialSecurityEmployeeForm from '../../../modules/employee-view/components/edit/SocialSecurityEmployeeForm'

const SocialSecurityEmployeeFormView = () => {
  const {formData, setFormData, updateFormField} = useContext(FormDataContext)

  return <SocialSecurityEmployeeForm formData={formData} setFormData={setFormData} updateFormField={updateFormField} />
}

export default SocialSecurityEmployeeFormView
