import {PropTypes} from 'prop-types'
import React, {createContext, useContext, useRef, useState} from 'react'

import PayrollReportModal from '../components/PayrollReportModal'

const PayrollReportModalWrapperContext = createContext({})

const PayrollReportModalWrapperProvider = ({children}) => {
  const payrollReportModalRef = useRef()
  const [isDownloadingReport, setIsDownloadingReport] = useState(false)

  return (
    <PayrollReportModalWrapperContext.Provider
      value={{
        payrollReportModalRef,
        isDownloadingReport,
        setIsDownloadingReport
      }}
    >
      {children}
      <PayrollReportModal ref={payrollReportModalRef} />
    </PayrollReportModalWrapperContext.Provider>
  )
}

export const usePayrollReportModalWrapperContext = () => useContext(PayrollReportModalWrapperContext)

PayrollReportModalWrapperProvider.propTypes = {
  children: PropTypes.node
}

PayrollReportModalWrapperProvider.defaultProps = {
  children: null
}

export default PayrollReportModalWrapperProvider
