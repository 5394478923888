import {isEqual} from 'helpers/utils'
import React from 'react'

const useHandleSocialSecurityForm = ({setFormData, updateFormField}) => {
  const handleOnChangeEntity = (e, abbreviation) => {
    const value = e.target.value

    setFormData(state => ({
      ...state,
      entities: [
        ...state?.entities.map(e => {
          if (e.abbreviation !== abbreviation) return e
          return {
            ...e,
            social_security_entity_id: Number(value)
          }
        })
      ]
    }))
  }

  const handleOnChangeAffectPension = e => {
    updateFormField(e)

    // hide the pension found if exists
    if (isEqual(e.target.value, 'true')) {
      setFormData(state => ({
        ...state,
        entities: [
          ...state?.entities.map(e => {
            if (e.abbreviation !== 'AFP') return e

            return {...e, is_show: false}
          })
        ]
      }))

      return
    }

    // show the pension if exists
    if (isEqual(e.target.value, 'false')) {
      setFormData(state => ({
        ...state,
        entities: [
          ...state?.entities.map(e => {
            if (e.abbreviation !== 'AFP') return e

            return {...e, is_show: true}
          })
        ],
        // Reset PILA mapping if the employees is not pensioner
        mapping: {
          ...state.mapping,
          pila: {
            ...state.mapping.pila,
            subcontributor: 0
          }
        }
      }))
    }
  }

  const handleOnChangeIsColombianLivingAbroad = e => {
    updateFormField(e)

    // if isn't ColombianLivingAbroad reset the has_colombian_living_abroad_pay_health field
    if (isEqual(e.target.value, 'false'))
      setFormData(state => ({...state, has_colombian_living_abroad_pay_health: false}))
  }

  const handleOnChangePensionerType = e => {
    const target = e.target
    let value = target.value

    setFormData(state => ({
      ...state,
      mapping: {
        ...state.mapping,
        pila: {
          ...state.mapping.pila,
          subcontributor: Number(value)
        }
      }
    }))
  }

  return {
    handleOnChangeEntity,
    handleOnChangeAffectPension,
    handleOnChangePensionerType,
    handleOnChangeIsColombianLivingAbroad
  }
}

export default useHandleSocialSecurityForm
