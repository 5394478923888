import PropTypes from 'prop-types'
import React from 'react'

import {isEqual} from '../../../helpers/utils'
import {getContractFormattedDescription} from '../../../modules/employee-view/helpers/contract'
import DropdownItem from '../../Atoms/Items/DropdownItem'
import DropdownSelect from '../../Molecules/Dropsdown/DropdownSelect'

const ContractSelect = ({contracts, contractSelected, onChangeContractSelected}) => {
  const handleOnClick = newContract => {
    // To avoid unnecessary re-renders
    if (isEqual(newContract.contract.id, contractSelected.contract.id)) return

    onChangeContractSelected(newContract)
  }

  return (
    <DropdownSelect
      className="s-main-start s-column s-cross-start"
      labelClasses="smaller"
      selected={getContractFormattedDescription(
        contracts.find(contract => isEqual(contract.contract.id, contractSelected.contract.id)).contract
      )}
    >
      {contracts.map(contract => (
        <DropdownItem
          key={contract.contract.id}
          text={getContractFormattedDescription(contract.contract)}
          isActive={isEqual(contract.contract.id, contractSelected.contract.id)}
          onClick={() => handleOnClick(contract)}
        />
      ))}
    </DropdownSelect>
  )
}

ContractSelect.propTypes = {
  contracts: PropTypes.array.isRequired,
  onChangeContractSelected: PropTypes.func.isRequired,
  contractSelected: PropTypes.object.isRequired
}

ContractSelect.defaultProps = {
  contracts: [],
  onChangeContractSelected: () => {},
  contractSelected: {}
}

export default ContractSelect
