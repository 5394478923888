import {getEDitSocialSecurityInitialState} from 'modules/employee-view/helpers/social-security'
import {useEmployeeDataContext} from 'modules/employee-view/providers/EmployeeData'
import React, {forwardRef, useEffect} from 'react'

import SocialSecurityEmployeeForm from '../../../modules/employee-view/components/edit/SocialSecurityEmployeeForm'
import useSocialSecurityForm from '../../../modules/employee-view/hooks/form/useSocialSecurityForm'
import ModalTemplate from '../../Wrappers/ModalTemplate'

const SocialSecurityContractEditModal = forwardRef(({formData, setContractData}, ref) => {
  const formattedData = getEDitSocialSecurityInitialState({contract: formData})

  const handleCancel = () => {
    ref.current.closeModal()
  }

  const {updateFormField, handleSubmit, isLoading, setSocialSecurityData, socialSecurityData, resetForm} =
    useSocialSecurityForm({
      contractId: formData.contract.id,
      formattedData,
      setContractData,
      handleCancel
    })

  const handleStateChange = isOpen => {
    if (!isOpen) return
    resetForm()
  }

  return (
    <ModalTemplate ref={ref} onChangeOpen={handleStateChange} className="large">
      <h3 className="s-left s-mb-0">Seguridad Social</h3>
      <p className="small s-color-light-text s-mb-24px">Actualiza la información de seguridad social del contrato</p>
      <form className="s-mb-0" onSubmit={handleSubmit}>
        <SocialSecurityEmployeeForm
          formData={socialSecurityData}
          setFormData={setSocialSecurityData}
          updateFormField={updateFormField}
          isEdit
        />
        <div className="s-column s-cross-center m-row m-main-center s-flex-gap-16px s-100">
          <button type="button" className="button ghost cancel s-order-3 m-order-1" onClick={handleCancel}>
            Cancelar
          </button>
          <button type="submit" className="button s-order-1 m-order-3" disabled={isLoading}>
            {isLoading ? 'Actualizando...' : 'Actualizar'}
          </button>
        </div>
      </form>
    </ModalTemplate>
  )
})

SocialSecurityContractEditModal.displayName = 'SocialSecurityContractEditModal'

export default SocialSecurityContractEditModal
