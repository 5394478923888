export const EMPLOYEE_DATA_FIELDS = [
  {
    description: 'Identificación',
    value: 'identification_number',
    isFixed: true
  },
  {
    description: 'Cód. Contrato',
    value: 'contract_hash'
  },
  {
    description: 'Código alterno',
    value: 'alternate_code'
  },
  {
    description: 'Código externo',
    value: 'partner_alternate_code'
  },
  {
    description: 'Nombres',
    value: 'first_name'
  },
  {
    description: 'Apellidos',
    value: 'last_name'
  },
  {
    description: 'Área',
    value: 'area'
  },
  {
    description: 'Cargo',
    value: 'job'
  },
  {
    description: 'Banco',
    value: 'bank_name'
  }
]

export const CONCEPT_TYPE_FIELDS = [
  {description: 'Ingresos', value: 'I'},
  {description: 'Descuentos', value: 'D'},
  {description: 'Provisiones', value: 'PR'},
  {description: 'Ingresos Puente', value: 'II'},
  {description: 'Descuentos Puente', value: 'DI'},
  {description: 'Conceptos Especiales', value: 'NO'}
]
