import {useContext, useState} from 'react'
import {useDispatch} from 'react-redux'

import {PayrollContext} from '../../../contexts/contexts'
import {isEmpty} from '../../../helpers/utils'
import {notifyError} from '../../../services/notification'
import {getByEmployerIDAndHash} from '../../../services/payrollReports'
import {useDownloadLinkReportContext} from '../../employees-panel/providers/DownloadLinkReport'
import {PAYROLL_REPORT_FORM_INITIAL_STATE} from '../constants/form'
import {getPayrollReportRequestQueryParams} from '../helpers/query-params'
import {usePayrollReportModalWrapperContext} from '../providers/PayrollReportModalWrapper'

const usePayrollReportForm = ({modalRef}) => {
  const dispatch = useDispatch()
  const {activeProcess} = useContext(PayrollContext)

  const [formData, setFormData] = useState(PAYROLL_REPORT_FORM_INITIAL_STATE)
  const {setIsDownloadingReport} = usePayrollReportModalWrapperContext()
  const {setFileNameDownloaded, setObject, downloadLinkReport} = useDownloadLinkReportContext()

  const resetForm = isOpen => {
    if (!isOpen) return

    setFormData(PAYROLL_REPORT_FORM_INITIAL_STATE)
  }

  const handleSubmit = e => {
    e.preventDefault()

    if (isEmpty(formData.concepType)) {
      dispatch(notifyError('¡Upps! debes seleccionar al menos un tipo de concepto'))
      return
    }

    setIsDownloadingReport(true)

    const {employeeData, concepType} = formData
    const requestQueries = getPayrollReportRequestQueryParams(employeeData, concepType)

    getByEmployerIDAndHash(
      activeProcess.hash,
      requestQueries,
      (response, headers) => {
        setObject(response)
        setFileNameDownloaded(headers['x-file-name'])

        downloadLinkReport.current.click()

        // after of clicked then reset the state
        setObject(null)
        setFileNameDownloaded('')
        setIsDownloadingReport(false)
        modalRef.current.closeModal()
      },
      error => {
        dispatch(notifyError('¡Uy! algo falló cuando generabamos el reporte.'))
        setIsDownloadingReport(false)
      }
    )
  }

  return {
    formData,
    setFormData,
    resetForm,
    handleSubmit
  }
}

export default usePayrollReportForm
