import {COLOMBIA_CODE, PERU_CODE} from 'helpers/country'
import {hasFounds, pensionerTypes, titleFunds} from 'helpers/socialsecurity'
import React, {useEffect, useRef, useState} from 'react'
import {useSelector} from 'react-redux'

import {getCacheKeyGeneral, getLocalCache} from '../../../helpers/cache'
import {isEqual, isNull} from '../../../helpers/utils'
import {selectors as employerSelectors} from '../../../redux/ducks/employer'
import {selectors as workplaceSelector} from '../../../redux/ducks/workplaces'
import PanelInformationItem from '../../Atoms/Items/PanelInformationItem'
import EmployeeInformationContainer from '../Containers/EmployeeInformationContainer'
import SocialSecurityContractEditModal from '../Modals/SocialSecurityContractEditModal'
import SocialSecurityFundEditModal from '../Modals/SocialSecurityFundEditModal'

const SocialSecurityPanel = ({contractData, setContractData}) => {
  const workplaces = useSelector(workplaceSelector.getAll)
  const employerSession = useSelector(employerSelectors.getSession)
  const cacheGeneral = getLocalCache(getCacheKeyGeneral(employerSession.code_iso_3))
  const contractTypeEntityType = !isNull(cacheGeneral) && cacheGeneral.data?.contract_types_entity_types

  const [fundTypeModal, setFundTypeModal] = useState({id: 0, code: ''})
  const editModal = useRef()
  const socialSecurityFundEditModal = useRef()

  const findWorkplaceName = () => {
    const workplace = workplaces?.filter(w => isEqual(w.id, contractData.contract.workplace_id))
    if (!workplace || !isEqual(workplace.length, 1)) return null
    return workplace[0].description
  }

  const handleOnEdit = () => {
    editModal.current.openModal()
  }

  const handleOnEditSocialSecurityFund = entityTypeObj => {
    setFundTypeModal(entityTypeObj)
    socialSecurityFundEditModal.current.openModal()
  }

  const isColombia = isEqual(employerSession.code_iso_3, COLOMBIA_CODE)
  const isPeru = isEqual(employerSession.code_iso_3, PERU_CODE)

  return (
    hasFounds(contractData.contract.contract_type_id, contractTypeEntityType) && (
      <>
        <EmployeeInformationContainer
          title="Seguridad social"
          icon="shield"
          // to disable edit feature if it's not Colombia
          editFunction={isColombia ? handleOnEdit : undefined}
        >
          {isColombia && (
            <>
              <PanelInformationItem title="Centro de trabajo ARL" text={findWorkplaceName() || ''} />
              <PanelInformationItem title="¿Es pensionado?" text={contractData.contract.is_pensioner ? 'Sí' : 'No'} />
              {contractData.contract.is_pensioner && (
                <PanelInformationItem
                  title="Tipo Pensionado"
                  text={
                    pensionerTypes.find(t => isEqual(t.id, contractData?.contract?.mapping?.pila?.subcontributor))
                      ?.description
                  }
                />
              )}
              <PanelInformationItem
                title="¿Extranjero sin pensión?"
                text={contractData.contract.is_foreigner_without_pension ? 'Sí' : 'No'}
              />
            </>
          )}

          {isPeru && (
            <>
              <PanelInformationItem title="Tipo Comisión AFP" text={contractData.contract.afp_commission_type} />
            </>
          )}

          {/* Social Security Founds */}
          {Array.isArray(contractData.employee_entity_history) &&
            contractData.employee_entity_history.map(e => (
              <PanelInformationItem
                key={e.employee_entity_history.id}
                title={titleFunds[e.entity_type_code]}
                text={e.social_security_entity_name}
                hasAction
                handleAction={() =>
                  handleOnEditSocialSecurityFund({
                    id: e.employee_entity_history.entity_type_id,
                    code: e.entity_type_code
                  })
                }
              />
            ))}

          {/* <PanelInformationItem
        title="¿Colombiano en el extranjero? / ¿Cotiza Salud?"
        text=""
        twoColumns
      /> */}
        </EmployeeInformationContainer>
        <SocialSecurityContractEditModal ref={editModal} formData={contractData} setContractData={setContractData} />

        <SocialSecurityFundEditModal
          ref={socialSecurityFundEditModal}
          data={contractData}
          onUpdate={setContractData}
          fundType={fundTypeModal}
        />
      </>
    )
  )
}

export default SocialSecurityPanel
