import {COLOMBIA_CODE, PERU_CODE} from 'helpers/country'
import useGetContractDataFromCache from 'modules/employee-view/hooks/form/useGetContractDataFromCache'
import {useEmployeeDataContext} from 'modules/employee-view/providers/EmployeeData'
import React from 'react'

import RadioButton from '../../../../components/Atoms/Forms/RadioButton'
import Select from '../../../../components/Atoms/Forms/Select'
import RadioButtonsWrapper from '../../../../components/Wrappers/RadioButtonsWrapper'
import {hasFoundContractType, hasFounds, isIntern, pensionerTypes} from '../../../../helpers/socialsecurity'
import {isEqual, isNull} from '../../../../helpers/utils'
import {SOCIAL_SECURITY_FOUND} from '../../constants/founds'
import {getEntitiesByType, getIsForeignIdentificationType} from '../../helpers/social-security'
import useHandleSocialSecurityForm from '../../hooks/form/useHandleSocialSecurityForm'
import SocialSecurityEmployeeFormFallback from './SocialSecurityEmployeeFormFallback'

const SocialSecurityEmployeeForm = ({formData, setFormData, updateFormField, isEdit}) => {
  const {
    handleOnChangeAffectPension,
    handleOnChangeEntity,
    handleOnChangeIsColombianLivingAbroad,
    handleOnChangePensionerType
  } = useHandleSocialSecurityForm({setFormData, updateFormField})
  const {workplaces, employerSession, cacheGeneral, cacheSocSec} = useGetContractDataFromCache()
  const {employee} = useEmployeeDataContext()

  const contractTypeEntityType = !isNull(cacheGeneral) && cacheGeneral.data?.contract_types_entity_types
  const contractTypes = !isNull(cacheGeneral) && cacheGeneral.data?.contract_types
  const entities = !isNull(cacheSocSec) && cacheSocSec.data

  const showSocialSecurityFoundOptions = hasFoundContractType(
    SOCIAL_SECURITY_FOUND,
    formData.contract_type_id,
    contractTypeEntityType
  )

  const showForm = hasFounds(formData.contract_type_id, contractTypeEntityType)
  const isColombia = isEqual(employerSession.code_iso_3, COLOMBIA_CODE)
  const isPeru = isEqual(employerSession.code_iso_3, PERU_CODE)

  if (!showForm)
    return (
      <SocialSecurityEmployeeFormFallback
        text="No necesitas registrar información de seguridad social para este contrato."
        isEdit={isEdit}
      />
    )

  const isDocumentForeign = isEdit
    ? getIsForeignIdentificationType(
        employee?.employee?.identification_type_id,
        cacheGeneral.data?.identification_types
      )
    : formData.is_document_foreign

  return (
    <>
      <div className="form-grid ed-grid m-grid-2 lg-grid-3">
        {isColombia && (
          <>
            {isDocumentForeign && !isIntern(formData.contract_type_id, contractTypes) && (
              <RadioButtonsWrapper label="¿Es extranjero sin pensión?">
                <RadioButton
                  name="is_foreigner_without_pension"
                  label="Sí"
                  value={true}
                  onChange={handleOnChangeAffectPension}
                  checked={isEqual(formData.is_foreigner_without_pension, true)}
                />
                <RadioButton
                  name="is_foreigner_without_pension"
                  label="No"
                  value={false}
                  onChange={handleOnChangeAffectPension}
                  checked={isEqual(formData.is_foreigner_without_pension, false)}
                />
              </RadioButtonsWrapper>
            )}

            {!isDocumentForeign && !isIntern(formData.contract_type_id, contractTypes) && (
              <>
                <RadioButtonsWrapper label="¿Es pensionado?">
                  <RadioButton
                    name="is_pensioner"
                    label="Sí"
                    value={true}
                    onChange={handleOnChangeAffectPension}
                    checked={isEqual(formData.is_pensioner, true)}
                  />
                  <RadioButton
                    name="is_pensioner"
                    label="No"
                    value={false}
                    onChange={handleOnChangeAffectPension}
                    checked={isEqual(formData.is_pensioner, false)}
                  />
                </RadioButtonsWrapper>

                {formData.is_pensioner && (
                  <Select
                    isRequired
                    label="Tipo Pensionado"
                    name="subcontributor"
                    id="subcontributor"
                    placeholder
                    value={formData.mapping.pila.subcontributor}
                    onChange={handleOnChangePensionerType}
                  >
                    {pensionerTypes.map(type => (
                      <option key={type.id} value={type.id}>
                        {`${type.id} - ${type.description}`}
                      </option>
                    ))}
                  </Select>
                )}

                <RadioButtonsWrapper label="¿Es colombiano en el extranjero?">
                  <RadioButton
                    name="is_colombian_living_abroad"
                    label="Sí"
                    value={true}
                    onChange={handleOnChangeIsColombianLivingAbroad}
                    checked={isEqual(formData.is_colombian_living_abroad, true)}
                  />
                  <RadioButton
                    name="is_colombian_living_abroad"
                    label="No"
                    value={false}
                    onChange={handleOnChangeIsColombianLivingAbroad}
                    checked={isEqual(formData.is_colombian_living_abroad, false)}
                  />
                </RadioButtonsWrapper>
              </>
            )}

            {formData.is_colombian_living_abroad && !isIntern(formData.contract_type_id, contractTypes) && (
              <RadioButtonsWrapper label="¿Colombiano en el extranjero cotiza Salud?">
                <RadioButton
                  name="has_colombian_living_abroad_pay_health"
                  label="Sí"
                  value={true}
                  onChange={updateFormField}
                  checked={isEqual(formData.has_colombian_living_abroad_pay_health, true)}
                />
                <RadioButton
                  name="has_colombian_living_abroad_pay_health"
                  label="No"
                  value={false}
                  onChange={updateFormField}
                  checked={isEqual(formData.has_colombian_living_abroad_pay_health, false)}
                />
              </RadioButtonsWrapper>
            )}
          </>
        )}

        {isPeru && (
          <Select
            isRequired
            label="Tipo comisión AFP"
            name="afp_commission_type"
            id="afp_commission_type"
            placeholder
            value={formData.afp_commission_type}
            onChange={updateFormField}
          >
            {['MIXTA', 'SOBRE FLUJO'].map(type => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </Select>
        )}

        {/* Social Security Founds */}
        {showSocialSecurityFoundOptions && (
          <Select
            isRequired
            label="Centro de trabajo ARL"
            name="workplace_id"
            id="workplace_id"
            placeholder
            value={formData.workplace_id}
            onChange={updateFormField}
          >
            {Array.isArray(workplaces) &&
              workplaces.map(workplace => (
                <option key={workplace.id} value={workplace.id}>
                  {workplace.description}
                </option>
              ))}
          </Select>
        )}

        {Array.isArray(formData.entities) &&
          !isEdit &&
          formData.entities.map(
            t =>
              t.is_show && (
                <Select
                  key={t.id}
                  isRequired={t.is_required}
                  label={t.description}
                  name={t.abbreviation}
                  id={t.abbreviation}
                  placeholder
                  value={t.social_security_entity_id}
                  onChange={e => handleOnChangeEntity(e, t.abbreviation)}
                >
                  {getEntitiesByType(t.abbreviation, entities).map(e => (
                    <option key={e.id} value={e.id}>
                      {e.short_name}
                    </option>
                  ))}
                </Select>
              )
          )}
      </div>

      <p className="smaller s-main-center s-pt-4 s-mb-0">
        Los campos con <span className="s-color-blue">*</span> son obligatorios
      </p>
    </>
  )
}

export default SocialSecurityEmployeeForm
