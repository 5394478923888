import classNames from 'classnames'
import React, {forwardRef} from 'react'

import Checkbox from '../../../components/Atoms/Forms/Checkbox'
import ModalTemplate from '../../../components/Wrappers/ModalTemplate'
import {CONCEPT_TYPE_FIELDS, EMPLOYEE_DATA_FIELDS} from '../data/payroll-report'
import usePayrollReportForm from '../hooks/usePayrollReportForm'
import {usePayrollReportModalWrapperContext} from '../providers/PayrollReportModalWrapper'

const PayrollReportModal = forwardRef(({}, ref) => {
  const {formData, handleSubmit, resetForm, setFormData} = usePayrollReportForm({modalRef: ref})
  const {isDownloadingReport} = usePayrollReportModalWrapperContext()

  const handleChangeEmployeeData = e => {
    const name = e.target.name

    setFormData(state => {
      const {employeeData} = state
      const isChecked = employeeData.includes(name)

      if (isChecked) {
        return {
          ...state,
          employeeData: employeeData.filter(field => field !== name)
        }
      }

      return {
        ...state,
        employeeData: [...employeeData, name]
      }
    })
  }

  const handleChangeConceptType = e => {
    const name = e.target.name

    setFormData(state => {
      const {concepType} = state
      const isChecked = concepType.includes(name)

      if (isChecked) {
        return {
          ...state,
          concepType: concepType.filter(field => field !== name)
        }
      }

      return {
        ...state,
        concepType: [...concepType, name]
      }
    })
  }

  return (
    <ModalTemplate ref={ref} className="s-center jumbo" onChangeOpen={resetForm}>
      <>
        <h3>Reporte de Planilla</h3>
        <p>Selecciona las columnas que quieres ver en tu reporte de planilla</p>
        <form onSubmit={handleSubmit} className="s-mb-0">
          <h4 className="s-left">Datos del empleado</h4>
          <div className="ed-grid s-grid-1 m-grid-3 lg-grid-4 s-mb-3">
            {EMPLOYEE_DATA_FIELDS.map(field => (
              <Checkbox
                key={field.value}
                label={field.description}
                name={field.value}
                checked={formData.employeeData.includes(field.value)}
                onChange={handleChangeEmployeeData}
                isDisabled={field.isFixed}
              />
            ))}
          </div>

          <h4 className="s-left">Tipos de concepto</h4>
          <div className="ed-grid s-grid-1 m-grid-3 s-mb-4">
            {CONCEPT_TYPE_FIELDS.map(field => (
              <Checkbox
                key={field.value}
                label={field.description}
                name={field.value}
                checked={formData.concepType.includes(field.value)}
                onChange={handleChangeConceptType}
              />
            ))}
          </div>

          <button
            className={classNames({grayscale: isDownloadingReport}, 'button')}
            type="submit"
            disabled={isDownloadingReport}
          >
            Descargar
          </button>
        </form>
      </>
    </ModalTemplate>
  )
})

PayrollReportModal.displayName = 'PayrollReportModal'

export default PayrollReportModal
