import classNames from 'classnames'
import React from 'react'

const SocialSecurityEmployeeFormFallback = ({text, isEdit}) => {
  return (
    <div className="form-grid ed-grid m-grid-2 lg-grid-3">
      <p
        className={classNames(
          {'s-mb-0': !isEdit, 's-mb-4': isEdit},
          's-cols-1 m-cols-2 lg-cols-3 s-px-6px s-py-8px s-center small s-color-light-text s-bg-body-alt'
        )}
      >
        <span role="img" aria-label="" className="s-mr-8px">
          💡
        </span>
        <span>{text}</span>
      </p>
    </div>
  )
}

export default SocialSecurityEmployeeFormFallback
