import React, {forwardRef, useState} from 'react'

import {isEqual} from '../../../helpers/utils'
import {randomID} from '../../../helpers/utils'
import RadioButton from '../../Atoms/Forms/RadioButton'
import FormInput from '../../Atoms/Templates/FormInput'
import ModalTemplate from '../../Wrappers/ModalTemplate'
import RadioButtonsWrapper from '../../Wrappers/RadioButtonsWrapper'

const TYPE_NORMAL = 'NORMAL'
const TYPE_REPLACEMENT = 'REPLACEMENT'

const ElectronicPayrollSendModal = forwardRef(({onAccept = () => {}}, ref) => {
  const [documentType, setDocumentType] = useState(TYPE_NORMAL)
  const [confirmationCode, setConfirmationCode] = useState(randomID(4))
  const [inputConfirmationCode, setInputConfirmationCode] = useState('')

  const handleAccept = () => {
    onAccept(isEqual(documentType, TYPE_REPLACEMENT))
    ref.current.closeModal()
  }

  const resetForm = isOpen => {
    if (!isOpen) return

    setDocumentType(TYPE_NORMAL)
    setConfirmationCode(randomID(4))
    setInputConfirmationCode('')
  }

  const updateRadioButton = e => {
    const value = e.target.value

    setDocumentType(value)
  }

  const handleOnChangeInput = e => {
    const value = e.target.value
    setInputConfirmationCode(value)
  }

  return (
    <ModalTemplate ref={ref} className="s-center medium" onChangeOpen={resetForm}>
      <h3>Envia tus documentos</h3>
      <div className="ed-grid s-grid-1 rows-gap">
        <p className="s-mb-0">
          ¿Estás seguro de continuar?, esta acción no se puede revertir, enviará todos los borradores a la DIAN para su
          aceptación, selecciona el tipo de documentos a enviar:
        </p>
        <div>
          <RadioButtonsWrapper label="Tipo Documento" className="s-mb-1">
            <RadioButton
              name="selection_type"
              label="Documentos normales"
              value={TYPE_NORMAL}
              onChange={updateRadioButton}
              checked={isEqual(documentType, TYPE_NORMAL)}
            />

            <RadioButton
              name="selection_type"
              label="Documentos de remplazo"
              value={TYPE_REPLACEMENT}
              onChange={updateRadioButton}
              checked={isEqual(documentType, TYPE_REPLACEMENT)}
            />
          </RadioButtonsWrapper>
        </div>
        <div className="s-mb-24px ed-grid s-grid-4">
          <span className="s-cols-4 s-mb-1">
            Escribe el código <span className="s-color-blue">{confirmationCode}</span> para confirmar
          </span>
          <FormInput
            value={inputConfirmationCode}
            onChange={handleOnChangeInput}
            align="center"
            className="s-cols-2 s-x-2"
          />
        </div>
      </div>

      <button className="button" onClick={handleAccept} disabled={confirmationCode !== inputConfirmationCode}>
        Aceptar
      </button>
    </ModalTemplate>
  )
})

ElectronicPayrollSendModal.displayName = 'ElectronicPayrollSendModal'

export default ElectronicPayrollSendModal
