import React, {useEffect} from 'react'
import {useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {Redirect, Switch, useRouteMatch} from 'react-router-dom'

import CreateNewEmployee from '../components/Organisms/CreateNewEmployee'
import BranchOfficeEmptyState from '../components/Organisms/Emptys/BranchOfficeEmptyState'
import EmployeesEmptyState from '../components/Organisms/Emptys/EmployeesEmptyState'
import EmployeeInformationGrid from '../components/Organisms/Grids/EmployeeInformationGrid'
import EmployeesSection from '../components/Organisms/Sections/EmployeesSection'
import EmployeeWrapper from '../components/Wrappers/EmployeeWrapper'
import PhonePanel from '../components/Wrappers/PhonePanel'
import TranslatePhonePanel from '../components/Wrappers/TranslatePhonePanel'
import routes, {redirectRoute} from '../config/routes'
import PrivateEmployer from '../config/Routes/PrivateEmployer'
import {isEmpty} from '../helpers/utils'
import EmployeeDataProvider from '../modules/employee-view/providers/EmployeeData'
import {selectors as branchOfficeSelector} from '../redux/ducks/branchOffices'

const Employees = () => {
  let {path, isExact} = useRouteMatch()
  const history = useHistory()
  const branchOffices = useSelector(branchOfficeSelector.getAll)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return isEmpty(branchOffices) ? (
    <BranchOfficeEmptyState />
  ) : (
    <EmployeeWrapper>
      <main>
        <PhonePanel
          container={{
            className: 'ed-grid s-gap-0 m-gap-2 lg-gap-4 m-grid-2 lg-grid-3'
          }}
          onClickComeBack={() => {
            history.push(routes.employees.base)
          }}
        >
          <div>
            <EmployeesSection />
          </div>
          <TranslatePhonePanel
            className={`s-gap-1 m-gap-2 lg-gap-4 ed-grid lg-grid-2 lg-cols-2 row-gap ${
              // isExact only when render <EmployeesEmptyState />, this allow center the
              // <EmployeesEmptyState /> and other wise align the page in cross-start
              !isExact && 's-cross-start'
            }`}
          >
            <Switch>
              {/* new employee */}
              <PrivateEmployer exact path={`${path}${routes.employees.new}`}>
                <CreateNewEmployee />
              </PrivateEmployer>

              {/* show employee */}
              <PrivateEmployer exact path={`${path}${routes.employees.query}/:hash`}>
                <EmployeeDataProvider>
                  <EmployeeInformationGrid />
                </EmployeeDataProvider>
              </PrivateEmployer>

              {/* empty state */}
              <PrivateEmployer exact path={`${path}`}>
                <EmployeesEmptyState />
              </PrivateEmployer>

              <Redirect to={redirectRoute.privateEmployer} />
            </Switch>
          </TranslatePhonePanel>
        </PhonePanel>
      </main>
    </EmployeeWrapper>
  )
}

export default Employees
