import {isEqual} from 'helpers/utils'
import React, {createContext, Dispatch, ReactNode, SetStateAction, useContext, useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {useHistory, useParams} from 'react-router-dom'
import {getEmployeeByHash} from 'services/employees'
import {notifyError} from 'services/notification'

import routes from '../../../config/routes'

const EmployeeDataContext = createContext({} as EmployeeDataContextProps)

const EmployeeDataProvider = ({children}: EmployeeDataProviderProps) => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(true)
  const [employee, setEmployee] = useState({})
  const [contractSelected, setContractSelected] = useState({})

  const {hash} = useParams()
  const history = useHistory()

  useEffect(() => {
    window.scrollTo({top: 0, behavior: 'smooth'})

    const handleFailureFetch = error => {
      setIsLoading(false)
      history.push(routes.employees.base)
      dispatch(notifyError(error))
    }

    const handleSuccessfulFetch = response => {
      if (isEqual(response, '')) {
        handleFailureFetch('¡Upps! el empleado no existe.')
        return
      }

      setEmployee(response)
      setContractSelected(response.contracts[0])
      setIsLoading(false)
    }

    setIsLoading(true)
    getEmployeeByHash(hash, handleSuccessfulFetch, handleFailureFetch)
  }, [hash]) // eslint-disable-line

  return (
    <EmployeeDataContext.Provider
      value={{
        employee,
        setEmployee,
        isLoading,
        contractSelected,
        setContractSelected
      }}
    >
      {children}
    </EmployeeDataContext.Provider>
  )
}

export const useEmployeeDataContext = () => useContext(EmployeeDataContext)

type EmployeeDataContextProps = {
  employee: {}
  setEmployee: Dispatch<SetStateAction<{}>>
  isLoading: boolean
  contractSelected: {}
  setContractSelected: Dispatch<SetStateAction<{}>>
}

type EmployeeDataProviderProps = {
  children: ReactNode
}

export default EmployeeDataProvider
